<template>
  <div class="row campaign mt-2">
    <b-col lg="12">
      <div class="card">
        <div class="card-body">
          <div class="form-group row">
            <div class="col-sm-6">
              <b-form-input
                type="search"
                placeholder="Type to search"
                v-model="filter"
                @input="asyncFind"
                v-on:keyup.enter="asyncFind(filter)"
              ></b-form-input>
            </div>
            <div class="col-sm-1">
              <router-link
                class="btn btn-block btn-primary"
                :to="{ path: '/campaign/create' }"
              >
                <i class="fa fa-plus"></i>
              </router-link>
            </div>
            <div class="col justify-content-end d-flex align-items-end">
              <span>
                Count:
                <strong>{{ formatNumber(totalRows) }}</strong>
              </span>
            </div>
          </div>
          <b-table
            sticky-header="500px"
            :items="items"
            :fields="fields"
            :busy="isLoading"
            show-empty
            responsive="lx"
            hover
            borderless
            striped
          >
            <template #cell(No)="row">
              {{ (currentPage - 1) * perPage + (row.index + 1)}}
            </template>
            <template v-slot:cell(is_active)="row">
              <div>
                <span v-if="row.item.is_active" class="badge badge-success">
                  Active
                </span>
                <span v-else class="badge badge-danger">Inactive</span>
              </div>
            </template>
            <template #cell(valid_from)="row">
              <div style="width: 10em;">
                {{ new Date(row.item.start_date)  }}
              </div>
            </template>
            <template #cell(valid_to)="row">
              <div style="width:10em;">
                {{ new Date(row.item.end_date)  }}
              </div>
            </template>
            <template #cell(status)="row">
              <div style="width:10em;">
                <b-badge 
                  variant="primary"
                  v-if="isCurrentlyActive(row.item.end_date)"
                >
                  Campaign Currently Active
                </b-badge>
                <b-badge variant="danger" v-else>Campaign InActive</b-badge>
              </div>
            </template>
            <template #cell(start_date)="row">
              <div style="width: 10em;">
                {{ new Date(row.item.created)  }}
              </div>
            </template>
            <template #cell(action)>
            </template>
          </b-table>
        </div>
        <b-row class="justify-content-start ml-1">
          <b-col lg="3">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aria-controls="my-table"
            />
          </b-col>
        </b-row>
      </div>
    </b-col>
  </div>
</template>

<script>
import moment from "moment";
import { mapState, mapGetters, mapActions } from "vuex";
export default {
  name: "campaign",
  data() {
    return {
      currentPage: 1,
      perPage: 100,
      filter: null,
      isLoadingSearch: false,
      fields: [
        { key:'No', label: 'No'},
        { key:'name', label: 'Name'},
        { key:'is_active', label: 'Active', sortable:true},
        { key:'valid_from', label: 'Valid From'},
        { key:'valid_to' , label:' Valid to'},
        { key:'status', label: 'Status'},
        { key:'start_date', label: 'Created'},
        //{ key:'action', label: 'Action'}
      ]
    };
  },
  computed: {
    ...mapGetters("campaign", { items: "campaigns" }),
    ...mapState({
      isLoading: (state) => state.campaign.isLoading,
      isError: (state) => state.campaign.isError,
      totalRows: (state) => state.campaign.totalRows,
    }),
  },
  watch: {
    currentPage: function() {
      this.actionGetCampaign();
    },
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || 'Campaign List | Content Management System';
      }
    },
  },
  mounted() {
    this.actionGetCampaign();
  },
  methods: {
    ...mapActions("campaign", [
      "fetchCampaign",
      "searchCampaign",
      "fetchCampaignByid",
    ]),
    actionGetCampaign() {
      let payload = {
        page: this.currentPage,
        limit: this.perPage,
      };

      this.fetchCampaign(payload);
    },
    formatDate(tgl) {
      return moment(tgl).format("DD MMM YYYY");
    },
    formatDateValid(from, to) {
      return (
        new Date(from).toString() +
        " s/d " +
        new Date(to).toString()
      );
    },
    promotionType(key) {
      if (key == 1) {
        return "Discount Offers";
      } else if (key == 2) {
        return "Discount Orders";
      } else if (key == 3) {
        return "Discount payment gateway offer";
      } else if (key == 4) {
        return "Discount paytment gateway order";
      } else if (key == 5) {
        return "Discount Code";
      }
    },
    asyncFind(query) {
      if (!query) {
        this.actionGetCampaign();
        clearTimeout(this.debounce);
        return;
      }
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.searchCampaign({ q: query});
      }, 600);
    },
    formatNumber(value) {
      if (value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      }
      return '0';
    },
    isCurrentlyActive(validTo) {
      const now = moment();
      const to = moment(validTo);
      return to > now;
    }
  },
};
</script>

<style scoped>
</style>
