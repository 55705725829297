var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row campaign mt-2"
  }, [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "form-group row"
  }, [_c('div', {
    staticClass: "col-sm-6"
  }, [_c('b-form-input', {
    attrs: {
      "type": "search",
      "placeholder": "Type to search"
    },
    on: {
      "input": _vm.asyncFind,
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.asyncFind(_vm.filter);
      }
    },
    model: {
      value: _vm.filter,
      callback: function ($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  })], 1), _c('div', {
    staticClass: "col-sm-1"
  }, [_c('router-link', {
    staticClass: "btn btn-block btn-primary",
    attrs: {
      "to": {
        path: '/campaign/create'
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-plus"
  })])], 1), _c('div', {
    staticClass: "col justify-content-end d-flex align-items-end"
  }, [_c('span', [_vm._v(" Count: "), _c('strong', [_vm._v(_vm._s(_vm.formatNumber(_vm.totalRows)))])])])]), _c('b-table', {
    attrs: {
      "sticky-header": "500px",
      "items": _vm.items,
      "fields": _vm.fields,
      "busy": _vm.isLoading,
      "show-empty": "",
      "responsive": "lx",
      "hover": "",
      "borderless": "",
      "striped": ""
    },
    scopedSlots: _vm._u([{
      key: "cell(No)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s((_vm.currentPage - 1) * _vm.perPage + (row.index + 1)) + " ")];
      }
    }, {
      key: "cell(is_active)",
      fn: function (row) {
        return [_c('div', [row.item.is_active ? _c('span', {
          staticClass: "badge badge-success"
        }, [_vm._v(" Active ")]) : _c('span', {
          staticClass: "badge badge-danger"
        }, [_vm._v("Inactive")])])];
      }
    }, {
      key: "cell(valid_from)",
      fn: function (row) {
        return [_c('div', {
          staticStyle: {
            "width": "10em"
          }
        }, [_vm._v(" " + _vm._s(new Date(row.item.start_date)) + " ")])];
      }
    }, {
      key: "cell(valid_to)",
      fn: function (row) {
        return [_c('div', {
          staticStyle: {
            "width": "10em"
          }
        }, [_vm._v(" " + _vm._s(new Date(row.item.end_date)) + " ")])];
      }
    }, {
      key: "cell(status)",
      fn: function (row) {
        return [_c('div', {
          staticStyle: {
            "width": "10em"
          }
        }, [_vm.isCurrentlyActive(row.item.end_date) ? _c('b-badge', {
          attrs: {
            "variant": "primary"
          }
        }, [_vm._v(" Campaign Currently Active ")]) : _c('b-badge', {
          attrs: {
            "variant": "danger"
          }
        }, [_vm._v("Campaign InActive")])], 1)];
      }
    }, {
      key: "cell(start_date)",
      fn: function (row) {
        return [_c('div', {
          staticStyle: {
            "width": "10em"
          }
        }, [_vm._v(" " + _vm._s(new Date(row.item.created)) + " ")])];
      }
    }, {
      key: "cell(action)",
      fn: function () {
        return undefined;
      },
      proxy: true
    }])
  })], 1), _c('b-row', {
    staticClass: "justify-content-start ml-1"
  }, [_c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('b-pagination', {
    attrs: {
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "aria-controls": "my-table"
    },
    model: {
      value: _vm.currentPage,
      callback: function ($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }